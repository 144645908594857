import Initial from './pages/Initial';

import './assets/css/global.css';

function App() {
  return (
    <>
      <Initial/>
    </>
  );
}

export default App;
